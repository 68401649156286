import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import {
  getAllEmployee,
  getAllInactiveEmployee,
} from "../../../actions/employeeAction";
import Rendertable from "./Rendertable";
import { getAllLocation } from "../../../actions/warehouseAction";
import { getAllUserRole } from "../../../actions/userRoleAction";

const EmployeeList = ({
  getAllEmployee,
  getAllInactiveEmployee,
  employeeList,
  getAllLocation,
  updatelist,
  setUpdatelist,
  setData,
  getAllUserRole,
  loading,
  setloading,
  location,
  Auth,
  brand
}) => {
  const [inactiveEmployee, setinactiveEmployee] = useState([]);
  const [activeEmployee, setactiveEmployee] = useState([]);
  useEffect(() => {
    setloading(true);
    getAllEmployee(location.current).then((e) => {
      setactiveEmployee(e);
      getAllInactiveEmployee(location.current).then((res) => {
        setinactiveEmployee(res);
        setloading(false);
        setUpdatelist(true);
      });
    });
    getAllLocation();
    getAllUserRole();
  }, [updatelist]);

  useEffect(() => {
    setData(employeeList);
  }, [employeeList]);

  return (
    <>
      <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
        <Tabs.TabPane tab="Active Employees" key="1">
          <Rendertable
            loading={loading}
            setloading={setloading}
            List={employeeList}
            updatelist={updatelist}
            setUpdatelist={setUpdatelist}
            Auth={Auth}
            brand={brand}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Inactive Employees" key="2">
          <Rendertable
            loading={loading}
            setloading={setloading}
            List={inactiveEmployee}
            updatelist={updatelist}
            setUpdatelist={setUpdatelist}
            Auth={Auth}
            brand={brand}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelist,
    Auth: state.auth,
    brand: state.brand?.brandlist[0],
  };
};

export default connect(mapStateToProps, {
  getAllEmployee,
  getAllInactiveEmployee,
  getAllLocation,
  getAllUserRole,
})(EmployeeList);
