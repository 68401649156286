import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createPromotion } from "../../../actions/promotionAction";
import { getAllUserRole } from "../../../actions/userRoleAction";
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  DatePicker,
  Drawer,
  TreeSelect,
  Button,
  Col,
  Row,
  Select,
  message,
  Divider,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const CreateNewEmployeeLeave = ({
  createPromotion,
  getAllUserRole,
  RoleList,
  reload,
  setReload,
  details,
  flag
}) => {
  const initial = { remarks: "" };
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";

  const showDrawer = () => {
    // console.log(details)
    getAllUserRole();
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    values.previous_designation = details.employee.user_role.id
    values.salary = details.id
    createPromotion(values).then(res => {
      form.resetFields();
      setReload(!reload);
      setVisible(false);
    });
  };

  return (
    <>
      <>
        <Button
          type="primary"
          onClick={showDrawer}

        >
          <PlusOutlined /> New promotion
        </Button>
        <Drawer
          title={details.name}
          width={400}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initial}
          >

            <h3>Designation: {details.employeeRole}</h3>
            <h3>EPIN: {details.employee_pin}</h3>

            <Divider />
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="new_designation"
                  label="Designation"
                  rules={[
                    { required: true, message: "Please choose a Designation" },
                  ]}
                >
                  <Select showSearch placeholder="Please choose a Designation" optionFilterProp="children">
                    {RoleList.map(role => {
                      return <Option value={role.id}>{role.name}</Option>;
                    })}

                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="amount"
                  label="Increament Amount"
                  rules={[
                    { required: false, message: "Please enter an amount" },
                  ]}
                >
                  <Input placeholder="Increament Amount" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="remarks"
                  label="Remarks"
                >
                  <TextArea placeholder="remarks" />
                </Form.Item>
              </Col>
            </Row>


            <Form.Item>
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelist,
    RoleList: state.userRole.userRolelist,
  };
};

export default connect(mapStateToProps, {
  createPromotion,
  getAllUserRole,
})(CreateNewEmployeeLeave);
