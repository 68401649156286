import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar";
import Navbar from "../layout/Navbar";
import { getAllTransfer } from "../../actions/transfer";

import RenderTable from "./RenderTable";

import {
  Layout,
  Breadcrumb,
  DatePicker,
  Select,
  AutoComplete,
  Row,
  Col,
  Button,
  Divider,
} from "antd";
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ProductDetails = ({ getAllTransfer, Auth }) => {
  const [details, setdetails] = useState([]);
  const pageno = useRef(1);
  const page_size = useRef(20);
  const start = useRef("");
  const end = useRef("");
  const keyward = useRef("");
  const status = useRef("");
  const [reload, setReload] = useState(true);
  useEffect(() => {
    getAllTransfer(pageno.current, page_size.current, keyward.current, start.current, end.current, status.current).then(function (result) {
      // console.log(result);
      setdetails(result);
    });
  }, [reload]);

  const onChange = (event) => {
    if (event.keyCode == 13) {
      pageno.current = 1;
      keyward.current = event.target.value;
      setReload(!reload);
    }
  };

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Transfer</Breadcrumb.Item>
              <Breadcrumb.Item>All</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              <Row wrap={false}>
                <Col flex="auto">
                  <h3>Keyword :</h3>
                  <AutoComplete
                    placeholder="input search text"
                    onKeyUp={onChange}
                    style={{ width: "100%" }}
                  /></Col>
                <Col flex="auto">
                  <h3>Issue Date :</h3>
                  <RangePicker
                    style={{ width: "100%" }}
                    picker="date"
                    onChange={(value) => {
                      if (value) {
                        start.current =
                          value[0].format("YYYY-MM-DD") + "T00:00:00";
                        end.current =
                          value[1].format("YYYY-MM-DD") + "T23:59:59";
                        setReload(!reload);

                      }
                    }
                    }
                  />
                </Col>

                <Col flex="auto">
                  <h3>Status :</h3>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Status"
                    onChange={(value) => {
                      status.current = value;
                      setReload(!reload);
                    }}
                  >
                    <Option value="">All</Option>
                    <Option value="Processing">PROCESSING</Option>
                    <Option value="Received">RECEIVED</Option>
                    <Option value="Returned">RETURNED</Option>
                  </Select>
                </Col>


              </Row>
              <Divider />
              <RenderTable
                details={details}
                Auth={Auth}
                pageno={pageno}
                page_size={page_size}
                reload={reload}
                setReload={setReload}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Auth: state.auth,
  };
};

export default connect(mapStateToProps, { getAllTransfer })(ProductDetails);
