import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excelldownload = ({ data }) => {
  return (
    <ExcelFile
      element={
        <Button type="primary" style={{ width: "100%" }}>
          Download
        </Button>
      }
    >
      <ExcelSheet data={data} name="All Invoices">
        <ExcelColumn label="Date" value="issue_date" />
        <ExcelColumn label="Invoice No." value="invoice_number" />
        <ExcelColumn
          label="Client name"
          value={(details) => {
            if (details.contact) {
              return details.Contact[0].name;
            }
          }}
        />
        <ExcelColumn
          label="Cupon"
          value={(details) => {
            if (details.cupon) {
              return details.cupon.name;
            }
          }}
        />
        <ExcelColumn label="Remarks" value="remarks" />
        <ExcelColumn label="Discount" value="discount" />
        <ExcelColumn label="Outlet" value="Location_name" />
        <ExcelColumn label="Bill" value="bill" />


      </ExcelSheet>
    </ExcelFile>
  );
};

export default Excelldownload;
