import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Layout, Menu, Affix } from "antd";
import history from "../../history";

import {
  PieChartOutlined,
  ShopOutlined,
  UserOutlined,
  SkinOutlined,
  SettingOutlined,
  DatabaseOutlined,
  ShoppingCartOutlined,
  BarChartOutlined,
  SelectOutlined,
  ApartmentOutlined,
  DollarCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import { activelink } from "../../actions/authAction";
import { getAllBrand } from "../../actions/brandAction";
import MediaQuery from "react-responsive";

const { Sider } = Layout;
const { SubMenu } = Menu;

const routes = {
  "/": ["sub0", "1"],
  "/dashboard": ["sub0", "1"],
  "/customer": ["sub1", "11"],
  "/supplier": ["sub1", "12"],
  "/contact/options": ["sub1", "13"],
  "/department": ["sub2", "20"],
  "/user-role": ["sub2", "21"],
  "/employee": ["sub2", "22"],
  "/leave-type": ["sub2", "23"],
  "/employee-leave": ["sub2", "24"],
  "/attendance": ["sub2", "25"],
  "/import-attendance": ["sub2", "25"],
  "/attendance-report": ["sub2", "26"],
  "/attendance-report-short": ["sub2", "261"],
  "/salary": ["sub2", "27"],
  "/loan-management": ["sub2", "28"],
  "/pay-slip": ["sub2", "29"],
  "/salary-report": ["sub2", "291"],
  "/product": ["sub3", "31"],
  "/product/add": ["sub3", "32"],
  "/product/category": ["sub3", "33"],
  "/product/attribute": ["sub3", "34"],
  "/product/import": ["sub3", "35"],
  "/product/tag": ["sub3", "36"],
  "/seoproduct": ["sub3", "37"],
  "/liveproduct": ["sub3", "38"],
  "/Purchase": ["sub4", "41"],
  "/Purchase/add": ["sub4", "42"],
  "/stock": ["sub5", "51"],
  "/stock/packaging": ["sub5", "56"],
  "/stock/material": ["sub5", "57"],
  "/stock/alert": ["sub5", "52"],
  "/stock/transfer": ["sub5", "53"],
  "/stock/transfer/history": ["sub5", "54"],
  "/stock/history": ["sub5", "58"],
  "/order/add": ["sub6", "61"],
  "/order": ["sub6", "62"],
  "/allorder": ["sub6", "68"],
  "/service": ["sub6", "63"],
  "/cupons": ["sub6", "64"],
  "/order/report": ["sub6", "65"],
  "/order/customerprofile": ["sub6", "66"],
  "/refund": ["sub6", "67"],
  "/wordrobe": ["sub7", "71"],
  "/wordrobe/add": ["sub7", "72"],
  "/wordrobe/stock": ["sub7", "73"],
  "/report/sales": ["sub8", "81"],
  "/report/salesdue": ["sub8", "818"],
  "/report/delivery": ["sub8", "82"],
  "/report/service": ["sub8", "83"],
  "/report/vat": ["sub8", "811"],
  "/report/currentstock": ["sub8", "87"],
  "/report/StockValuation": ["sub8", "812"],
  "/report/alert": ["sub8", "88"],
  "/report/WordrobeStock": ["sub8", "89"],
  "/report/purchase": ["sub8", "810"],
  "/report/duecollection": ["sub8", "813"],
  "/report/PettyCashReport": ["sub8", "814"],
  "/report/soldporductreport": ["sub8", "815"],
  "/report/userlog": ["sub8", "816"],
  "/report/excelsalesreport": ["sub8", "817"],
  "/report/salesperson": ["sub8", "819"],
  "/report/refund": ["sub8", "820"],
  "/report/discount": ["sub8", "821"],
  "/warehouse": ["sub9", "91"],
  "/tools": ["sub9", "92"],
  "/office": ["sub9", "97"],
  "/outlet": ["sub9", "93"],
  "/contactgroup": ["sub9", "95"],
  "/businessprofile": ["sub9", "94"],
  "/deliverygroup": ["sub9", "96"],
  "/accountgroup": ["sub9", "98"],
  "/permissions": ["sub9", "99"],
  "/accounting/chartofaccounts": ["sub10", "102"],
  "/accounting/accounts": ["sub10", "101"],
  "/accounting/journals": ["sub10", "103"],
  "/accounting/journalvoucher": ["sub10", "105"],
  "/accounting/journalvoucher/create": ["sub10", "105"],
  "/accounting/paymentvoucher": ["sub10", "107"],
  "/accounting/paymentvoucher/create": ["sub10", "107"],
  "/accounting/receivevoucher": ["sub10", "108"],
  "/accounting/receivevoucher/create": ["sub10", "108"],
  "/accounting/contravoucher": ["sub10", "106"],
  "/accounting/contravoucher/create": ["sub10", "106"],
  "/accounting/trailbalance": ["sub10", "1010"],
  "/accounting/trailbalancemanual": ["sub10", "1010"],
  "/accounting/ledger": ["sub10", "109"],
  "/accounting/CashBankBook": ["sub10", "1091"],
  "/accounting/PayableAndReceivable": ["sub10", "1092"],
  // "/accounting/IncomeAndExpense": ["sub10", "1093"],
  "/accounting/incomestatement": ["sub10", "1030"],
  "/accounting/balancesheet": ["sub10", "1040"],
  "/accounting/branchsales": ["sub10", "1050"],
  "/accounting/expensereport": ["sub10", "1060"],
  "/accounting/accountstatus": ["sub10", "1070"],
  "/accounting/accountstatusnew": ["sub10", "1070"],
  "/accounting/cashreceivereport": ["sub10", "1080"],
  "/pettycash": ["sub0", "110"],
  "/ecommerceimage": ["sub11", "1101"],
  "/pages": ["sub11", "1102"],
  "/campaignpages": ["sub11", "1103"],
};

const Sidebar = ({ auth, activelink, businessprofile, getAllBrand, brandlist }) => {
  const [collapsed, setcollapsed] = useState();
  const [loading, setloading] = useState(true);
  const selectedkey = useRef();
  const openkey = useRef();

  useEffect(() => {
    if (history.location.pathname.split("/", 3)[2] == "image") {
      selectedkey.current = ["sub3", "32"];
      openkey.current = ["sub3", "32"];
    } else if (
      history.location.pathname.split("/", 3)[1] == "order" &&
      history.location.pathname.split("/", 3)[2] == "add"
    ) {
      selectedkey.current = ["sub6", "61"];
      openkey.current = ["sub6", "61"];
    } else {
      selectedkey.current = [routes[history.location.pathname][1]];
      openkey.current = [routes[history.location.pathname][0]];
    }


    getAllBrand()
    setcollapsed(auth.activeLink);
    setloading(false);
  }, [history.location.pathname]);

  const onCollapse = (collapsed) => {
    activelink(collapsed);
    setcollapsed(collapsed);
  };

  if (loading) {
    return "loading";
  } else
    return (
      <>
        <MediaQuery minWidth={1224}>
          <Sider
            collapsible
            collapsed={auth.activeLink}
            onCollapse={onCollapse}
            theme="dark"
            className={brandlist[0]?.name == "AZRAN" ? "azran-sidebar" : brandlist[0]?.name == "ELOR" ? "elor-sidebar" : "anzara-sidebar"}
          >
            {/* <Affix> */}
            <div
              className="logo"
              style={{
                backgroundColor: "transparent",
                height: "37px",
                marginTop: "15px",
                marginBottom: "15px",
                zIndex: "-99",
              }}
            >
              {!auth.activeLink ? (
                <Link aria-current="page" to="/dashboard">
                  <img
                    src={businessprofile.logo}
                    style={{ height: "40px", zIndex: "-1" }}
                  />
                </Link>
              ) : (
                <Link aria-current="page" to="/dashboard">
                  <img
                    // src={`${process.env.PUBLIC_URL}` + `/favicon.png`}
                    src={businessprofile.logo}
                    style={{ width: "50px", zIndex: "-1" }}
                  />
                </Link>
              )}

              {/* <span style={{ color: "white", marginLeft: "1.5rem" }}>
           
          </span> */}
            </div>
            <Menu
              theme="dark"
              className={brandlist[0]?.name == "AZRAN" ? "azran-sidebar" : "anzara-sidebar"}
              mode="inline"
              defaultSelectedKeys={selectedkey.current}
              defaultOpenKeys={openkey.current}
            >
              <Menu.Item key="1" icon={<PieChartOutlined />}>
                <Link aria-current="page" to="/dashboard">
                  Dashboard
                </Link>
              </Menu.Item>
              {auth.permissions.includes("Contacts.Customer_is_read") ||
                auth.permissions.includes("Contacts.Supplier_is_read") ||
                auth.permissions.includes("Contacts.Import/Export_is_read") ? (
                <SubMenu
                  key="sub1"
                  icon={<UserOutlined />}
                  defaultSelectedKeys={["10"]}
                  title="Contacts"
                >
                  {auth.permissions.includes("Contacts.Customer_is_read") ? (
                    <Menu.Item key="11">
                      <Link
                        aria-current="page"
                        to="/customer"
                        className="active"
                      >
                        Customer
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Contacts.Supplier_is_read") ? (
                    <Menu.Item key="12">
                      <Link aria-current="page" to="/supplier">
                        Supplier
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Contacts.Import/Export_is_read"
                  ) ? (
                    <Menu.Item key="13">
                      <Link aria-current="page" to="/contact/options">
                        Import/Export
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes("Ecommerce.Images_is_read") ||
                auth.permissions.includes("Ecommerce.Pages_is_read") ||
                auth.permissions.includes("Ecommerce.Campaign Pages_is_read") ? (
                <SubMenu
                  key="sub11"
                  icon={<ShopOutlined />}
                  defaultSelectedKeys={["10"]}
                  title="Ecommerce"
                >
                  {auth.permissions.includes("Ecommerce.Images_is_read") ? (
                    <Menu.Item key="1101">
                      <Link
                        aria-current="page"
                        to="/ecommerceimage"
                        className="active"
                      >
                        Images
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Ecommerce.Pages_is_read") ? (
                    <Menu.Item key="1102">
                      <Link aria-current="page" to="/pages">
                        Pages
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Ecommerce.Campaign Pages_is_read") ? (
                    <Menu.Item key="1103">
                      <Link aria-current="page" to="/campaignpages">
                        Campaign Pages
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes("HRM.Departments_is_read") ||
                auth.permissions.includes("HRM.User Roles_is_read") ||
                auth.permissions.includes("HRM.Employee_is_read") ||
                auth.permissions.includes("HRM.Salary Setup_is_read") ||
                auth.permissions.includes("HRM.LeaveType_is_read") ||
                auth.permissions.includes("HRM.Employee Leave_is_read") ||
                auth.permissions.includes("HRM.Attendance_is_read") ||
                auth.permissions.includes("HRM.Attendance List_is_read") ||
                auth.permissions.includes("HRM.Attendance Report_is_read") ||
                auth.permissions.includes("HRM.Loan Management_is_read") ||
                auth.permissions.includes("HRM.Pay-Slip_is_read") ||
                auth.permissions.includes("HRM.Salary Report_is_read") ? (
                <SubMenu
                  key="sub2"
                  icon={<TeamOutlined />}
                  defaultSelectedKeys={["20"]}
                  title="HRM"
                >
                  {auth.permissions.includes("HRM.Departments_is_read") ? (
                    <>
                      <Menu.Item key="20">
                        <Link aria-current="page" to="/department">
                          Departments
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("HRM.User Roles_is_read") ? (
                    <>
                      <Menu.Item key="21">
                        <Link aria-current="page" to="/user-role">
                          User Roles
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("HRM.Employee_is_read") ? (
                    <>
                      <Menu.Item key="22">
                        <Link aria-current="page" to="/employee">
                          Employee
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("HRM.Salary Setup_is_read") ? (
                    <>
                      <Menu.Item key="27">
                        <Link aria-current="page" to="/salary">
                          Salary Setup
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("HRM.LeaveType_is_read") ? (
                    <>
                      <Menu.Item key="23">
                        <Link aria-current="page" to="/leave-type">
                          LeaveType
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("HRM.Employee Leave_is_read") ? (
                    <>
                      <Menu.Item key="24">
                        <Link aria-current="page" to="/employee-leave">
                          Employee Leave
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("HRM.Attendance_is_read") ? (
                    <>
                      <Menu.Item key="25">
                        <Link aria-current="page" to="/attendance">
                          Attendance
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("HRM.Attendance List_is_read") ? (
                    <>
                      <Menu.Item key="26">
                        <Link aria-current="page" to="/attendance-report">
                          Attendance List
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "HRM.Attendance Report_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="261">
                        <Link aria-current="page" to="/attendance-report-short">
                          Attendance Report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes("HRM.Loan Management_is_read") ? (
                    <>
                      <Menu.Item key="28">
                        <Link aria-current="page" to="/loan-management">
                          Loan Management
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("HRM.Pay-Slip_is_read") ? (
                    <>
                      <Menu.Item key="29">
                        <Link aria-current="page" to="/pay-slip">
                          Pay-Slip
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("HRM.Salary Report_is_read") ? (
                    <>
                      <Menu.Item key="291">
                        <Link aria-current="page" to="/salary-report">
                          Salary Report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes(
                "Accounting.Chart of accounts_is_read"
              ) ||
                auth.permissions.includes(
                  "Accounting.Account settings_is_read"
                ) ||
                auth.permissions.includes("Accounting.Account status_is_read") ||
                auth.permissions.includes("Accounting.All entries_is_read") ||
                auth.permissions.includes("Accounting.Payment voucher_is_read") ||
                auth.permissions.includes("Accounting.Receive voucher_is_read") ||
                auth.permissions.includes(
                  "Accounting.Journal entry voucher_is_read"
                ) ||
                auth.permissions.includes("Accounting.Contra voucher_is_read") ||
                auth.permissions.includes(
                  "Accounting.Sales & petty cash_is_read"
                ) ||
                auth.permissions.includes("Accounting.Ledger_is_read") ||
                auth.permissions.includes(
                  "Accounting.Cash & Bank Ledger_is_read"
                ) ||
                auth.permissions.includes("Accounting.Expense Report_is_read") ||
                auth.permissions.includes(
                  "Accounting.Cash Receive Report_is_read"
                ) ||
                auth.permissions.includes(
                  "Accounting.Payable & Receivable_is_read"
                ) ||
                auth.permissions.includes("Accounting.Trail balance_is_read") ||
                auth.permissions.includes("Accounting.Profit & Loss_is_read") ||
                auth.permissions.includes("Accounting.Balance sheet_is_read") ? (
                <SubMenu
                  key="sub10"
                  icon={<DollarCircleOutlined />}
                  defaultSelectedKeys={["100"]}
                  title="Accounting"
                >
                  {auth.permissions.includes(
                    "Accounting.Chart of accounts_is_read"
                  ) ? (
                    <Menu.Item key="102">
                      <Link
                        aria-current="page"
                        to="/accounting/chartofaccounts"
                      >
                        Chart of accounts
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Account settings_is_read"
                  ) ? (
                    <Menu.Item key="101">
                      <Link aria-current="page" to="/accounting/accounts">
                        Account settings
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Account status_is_read"
                  ) ? (
                    <Menu.Item key="1070">
                      <Link aria-current="page" to="/accounting/accountstatus">
                        Account status
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Accounting.All entries_is_read"
                  ) ? (
                    <Menu.Item key="103">
                      <Link aria-current="page" to="/accounting/journals">
                        All entries
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Payment voucher_is_read"
                  ) ? (
                    <Menu.Item key="107">
                      <Link aria-current="page" to="/accounting/paymentvoucher">
                        Payment voucher
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Receive voucher_is_read"
                  ) ? (
                    <Menu.Item key="108">
                      <Link aria-current="page" to="/accounting/receivevoucher">
                        Receive voucher
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Journal entry voucher_is_read"
                  ) ? (
                    <Menu.Item key="105">
                      <Link aria-current="page" to="/accounting/journalvoucher">
                        Journal entry voucher
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Contra voucher_is_read"
                  ) ? (
                    <Menu.Item key="106">
                      <Link aria-current="page" to="/accounting/contravoucher">
                        Contra voucher
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Sales & petty cash_is_read"
                  ) ? (
                    <Menu.Item key="1050">
                      <Link aria-current="page" to="/accounting/branchsales">
                        Sales & petty cash
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Accounting.Ledger_is_read") ? (
                    <Menu.Item key="109">
                      <Link aria-current="page" to="/accounting/ledger">
                        Ledger
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Cash & Bank Ledger_is_read"
                  ) ? (
                    <Menu.Item key="1091">
                      <Link aria-current="page" to="/accounting/CashBankBook">
                        Cash & Bank Ledger
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Expense Report_is_read"
                  ) ? (
                    <Menu.Item key="1060">
                      <Link aria-current="page" to="/accounting/expensereport">
                        Expense Report
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Accounting.Cash Receive Report_is_read"
                  ) ? (
                    <Menu.Item key="1080">
                      <Link
                        aria-current="page"
                        to="/accounting/cashreceivereport"
                      >
                        Cash Receive Report
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Payable & Receivable_is_read"
                  ) ? (
                    <Menu.Item key="1092">
                      <Link
                        aria-current="page"
                        to="/accounting/PayableAndReceivable"
                      >
                        Payable & Receivable
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Trail balance_is_read"
                  ) ? (
                    <Menu.Item key="1010">
                      <Link aria-current="page" to="/accounting/trailbalance">
                        Trail balance
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Profit & Loss_is_read"
                  ) ? (
                    <Menu.Item key="1030">
                      <Link
                        aria-current="page"
                        to="/accounting/incomestatement"
                      >
                        Profit & Loss
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Accounting.Balance sheet_is_read"
                  ) ? (
                    <Menu.Item key="1040">
                      <Link aria-current="page" to="/accounting/balancesheet">
                        Balance sheet
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes("Products.Attribute_is_read") ||
                auth.permissions.includes("Products.Tags_is_read") ||
                auth.permissions.includes("Products.Live products_is_read") ||
                auth.permissions.includes("Products.Category_is_read") ||
                auth.permissions.includes("Products.Add new product_is_read") ||
                auth.permissions.includes("Products.All products_is_read") ||
                auth.permissions.includes("Products.SEO Products_is_read") ||
                auth.permissions.includes("Products.Import product_is_read") ? (
                <SubMenu
                  key="sub3"
                  icon={<SkinOutlined />}
                  defaultSelectedKeys={["30"]}
                  title="Products"
                >
                  {auth.permissions.includes("Products.Attribute_is_read") ? (
                    <>
                      <Menu.Item key="34">
                        <Link aria-current="page" to="/product/attribute">
                          Attribute
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Products.Tags_is_read") ? (
                    <>
                      <Menu.Item key="36">
                        <Link aria-current="page" to="/product/tag">
                          Tags
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Products.Category_is_read") ? (
                    <>
                      <Menu.Item key="33">
                        <Link aria-current="page" to="/product/category">
                          Category
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Products.Add new product_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="32">
                        <Link aria-current="page" to="/product/add">
                          Add new product
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Products.All products_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="31">
                        <Link aria-current="page" to="/product">
                          All products
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Products.Live products_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="38">
                        <Link aria-current="page" to="/liveproduct">
                          Live products
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Products.SEO Products_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="37">
                        <Link aria-current="page" to="/seoproduct">
                          SEO Products
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Products.Import product_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="35">
                        <Link aria-current="page" to="/product/import">
                          Import product
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes(
                "Inventory Purchase.Add new purchase_is_read"
              ) ||
                auth.permissions.includes(
                  "Inventory Purchase.All purchase_is_read"
                ) ? (
                <SubMenu
                  key="sub4"
                  icon={<SelectOutlined />}
                  defaultSelectedKeys={["40"]}
                  title="Inventory Purchase"
                >
                  {auth.permissions.includes(
                    "Inventory Purchase.Add new purchase_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="42">
                        <Link aria-current="page" to="/Purchase/add">
                          Add new purchase
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Inventory Purchase.All purchase_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="41">
                        <Link aria-current="page" to="/Purchase">
                          All purchase
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes("Stock.Current Stock_is_read") ||
                auth.permissions.includes("Stock.Packaging Stock_is_read") ||
                auth.permissions.includes("Stock.Material Stock_is_read") ||
                auth.permissions.includes("Stock.Stock Alert_is_read") ||
                auth.permissions.includes("Stock.Stock Transfer_is_read") ||
                auth.permissions.includes("Stock.Transfer History_is_read") ||
                auth.permissions.includes("Stock.Stock History_is_read") ? (
                <SubMenu
                  key="sub5"
                  icon={<DatabaseOutlined />}
                  defaultSelectedKeys={["50"]}
                  title="Stock"
                >
                  {auth.permissions.includes("Stock.Current Stock_is_read") ? (
                    <Menu.Item key="51">
                      <Link aria-current="page" to="/stock">
                        Current Stock
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Stock.Packaging Stock_is_read"
                  ) ? (
                    <Menu.Item key="56">
                      <Link aria-current="page" to="/stock/packaging">
                        Packaging Stock
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Stock.Material Stock_is_read") ? (
                    <Menu.Item key="57">
                      <Link aria-current="page" to="/stock/material">
                        Material Stock
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Stock.Stock Alert_is_read") ? (
                    <Menu.Item key="52">
                      <Link aria-current="page" to="/stock/alert">
                        Stock Alert
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Stock.Stock Transfer_is_read") ? (
                    <Menu.Item key="53">
                      <Link aria-current="page" to="/stock/transfer">
                        Stock Transfer
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Stock.Transfer History_is_read"
                  ) ? (
                    <Menu.Item key="54">
                      <Link aria-current="page" to="/stock/transfer/history">
                        Transfer History
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Stock.Stock History_is_read") ? (
                    <Menu.Item key="58">
                      <Link aria-current="page" to="/stock/history">
                        Stock History
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes("Sales.Add new order_is_read") ||
                auth.permissions.includes("Sales.Customer Profile_is_read") ||
                auth.permissions.includes("Sales.All orders_is_read") ||
                auth.permissions.includes("Sales.Orders_is_read") ||
                auth.permissions.includes("Sales.All Services_is_read") ||
                auth.permissions.includes("Sales.Refund_is_read") ||
                auth.permissions.includes("Sales.Coupons_is_read") ? (
                <SubMenu
                  key="sub6"
                  icon={<ShoppingCartOutlined />}
                  defaultSelectedKeys={["60"]}
                  title="Sales"
                >
                  {auth.permissions.includes(
                    "Sales.Customer Profile_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="66">
                        <Link aria-current="page" to="/order/customerprofile">
                          Customer Profile
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Sales.Add new order_is_read") ? (
                    <>
                      <Menu.Item key="61">
                        <Link aria-current="page" to="/order/add">
                          Add new order
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Sales.All orders_is_read") ? (
                    <>
                      <Menu.Item key="62">
                        <Link aria-current="page" to="/order">
                          All orders
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Sales.Orders_is_read") ? (
                    <>
                      <Menu.Item key="68">
                        <Link aria-current="page" to="/allorder">
                          Orders
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Sales.All Services_is_read") ? (
                    <>
                      <Menu.Item key="63">
                        <Link aria-current="page" to="/service">
                          All Services
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Sales.Refund_is_read") ? (
                    <>
                      <Menu.Item key="67">
                        <Link aria-current="page" to="/refund">
                          Refund
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Sales.Coupons_is_read") ? (
                    <Menu.Item key="64">
                      <Link aria-current="page" to="/cupons">
                        Coupons
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes("Petty Cash.Petty Cash_is_read") &&
                auth.profile.branch.id != 6 ? (
                <Menu.Item key="110" icon={<PieChartOutlined />}>
                  <Link aria-current="page" to="/pettycash">
                    Petty Cash
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {auth.permissions.includes(
                "Sponsorship.All sponsorship_is_read"
              ) ||
                auth.permissions.includes(
                  "Sponsorship.Add new sponsor_is_read"
                ) ||
                auth.permissions.includes(
                  "Sponsorship.S. floating stock_is_read"
                ) ? (
                <SubMenu
                  key="sub7"
                  icon={<ApartmentOutlined />}
                  defaultSelectedKeys={["70"]}
                  title="Sponsorship"
                >
                  {auth.permissions.includes(
                    "Sponsorship.Add new sponsor_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="72">
                        <Link aria-current="page" to="/wordrobe/add">
                          Add new sponsor
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Sponsorship.All sponsorship_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="71">
                        <Link aria-current="page" to="/wordrobe">
                          All sponsorship
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Sponsorship.S. floating stock_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="73">
                        <Link aria-current="page" to="/wordrobe/stock">
                          S. floating stock
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes("Report.Sales report_is_read") ||
                auth.permissions.includes("Report.Sales person report_is_read") ||
                auth.permissions.includes("Report.Discount report_is_read") ||
                auth.permissions.includes("Report.Due report_is_read") ||
                auth.permissions.includes("Report.Due collection_is_read") ||
                auth.permissions.includes("Report.Petty cash report_is_read") ||
                auth.permissions.includes("Report.Delivery report_is_read") ||
                auth.permissions.includes("Report.Service report_is_read") ||
                auth.permissions.includes("Report.VAT report_is_read") ||
                auth.permissions.includes("Report.Stock report_is_read") ||
                auth.permissions.includes("Report.Stock Valuation_is_read") ||
                auth.permissions.includes(
                  "Report.Sold Products Report_is_read"
                ) ||
                auth.permissions.includes("Report.Stock alert report_is_read") ||
                auth.permissions.includes("Report.Userlog_is_read") ||
                auth.permissions.includes("Report.Excel Sales Report_is_read") ||
                auth.permissions.includes(
                  "Report.S. floating stock report_is_read"
                ) ||
                auth.permissions.includes("Report.Purchase report_is_read") ? (
                <SubMenu
                  key="sub8"
                  icon={<BarChartOutlined />}
                  defaultSelectedKeys={["80"]}
                  title="Report"
                >
                  {auth.permissions.includes("Report.Sales report_is_read") ? (
                    <>
                      <Menu.Item key="81">
                        <Link aria-current="page" to="/report/sales">
                          Sales report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Report.Refund report_is_read") ? (
                    <>
                      <Menu.Item key="820">
                        <Link aria-current="page" to="/report/refund">
                          Refund report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Report.Sales person report_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="819">
                        <Link aria-current="page" to="/report/salesperson">
                          Sales person report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes("Report.Due report_is_read") ? (
                    <>
                      <Menu.Item key="818">
                        <Link aria-current="page" to="/report/salesdue">
                          Due report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Report.Discount report_is_read") ? (
                    <>
                      <Menu.Item key="821">
                        <Link aria-current="page" to="/report/discount">
                          Discount report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Report.Due collection_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="813">
                        <Link aria-current="page" to="/report/duecollection">
                          Due collection
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Report.Delivery report_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="82">
                        <Link aria-current="page" to="/report/delivery">
                          Delivery report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Report.Service report_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="83">
                        <Link aria-current="page" to="/report/service">
                          Service report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Report.VAT report_is_read") ? (
                    <>
                      <Menu.Item key="811">
                        <Link aria-current="page" to="/report/vat">
                          VAT report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Report.Petty cash report_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="814">
                        <Link aria-current="page" to="/report/PettyCashReport">
                          Petty cash report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes("Report.Stock report_is_read") ? (
                    <>
                      <Menu.Item key="87">
                        <Link aria-current="page" to="/report/currentstock">
                          Stock report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Report.Stock Valuation_is_read"
                  ) ? (
                    <Menu.Item key="812">
                      <Link aria-current="page" to="/report/StockValuation">
                        Stock Valuation
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Report.Sold Products Report_is_read"
                  ) ? (
                    <Menu.Item key="815">
                      <Link aria-current="page" to="/report/soldporductreport">
                        Sold Products Report
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Report.Stock alert report_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="88">
                        <Link aria-current="page" to="/report/alert">
                          Stock alert report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Report.S. floating stock report_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="89">
                        <Link aria-current="page" to="/report/WordrobeStock">
                          S. floating stock report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Report.Purchase report_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="810">
                        <Link aria-current="page" to="/report/purchase">
                          Purchase report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes("Report.Userlog_is_read") ? (
                    <>
                      <Menu.Item key="816">
                        <Link aria-current="page" to="/report/userlog">
                          Userlog
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Report.Excel Sales Report_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="817">
                        <Link aria-current="page" to="/report/excelsalesreport">
                          Excel Sales Report
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}

              {auth.permissions.includes("Settings.Office_is_read") ||
                auth.permissions.includes("Settings.Warehouse_is_read") ||
                auth.permissions.includes("Settings.Outlet_is_read") ||
                auth.permissions.includes("Settings.Business Profile_is_read") ||
                auth.permissions.includes("Settings.Contact Groups_is_read") ||
                auth.permissions.includes("Settings.Delivery Methods_is_read") ||
                auth.permissions.includes("Settings.Account Groups_is_read") ||
                auth.permissions.includes("Settings.Permissions_is_read") ||
                auth.superuser ? (
                <SubMenu
                  key="sub9"
                  icon={<SettingOutlined />}
                  defaultSelectedKeys={["90"]}
                  title="Settings"
                >
                  {auth.permissions.includes("Settings.Office_is_read") ? (
                    <>
                      <Menu.Item key="97">
                        <Link aria-current="page" to="/office">
                          Office
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Settings.Warehouse_is_read") ? (
                    <>
                      <Menu.Item key="91">
                        <Link aria-current="page" to="/warehouse">
                          Warehouse
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Settings.Outlet_is_read") ? (
                    <>
                      <Menu.Item key="93">
                        <Link aria-current="page" to="/outlet">
                          Outlet
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Settings.Business Profile_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="94">
                        <Link aria-current="page" to="/businessprofile">
                          Business Profile
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Settings.Contact Groups_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="95">
                        <Link aria-current="page" to="/contactgroup">
                          Contact Groups
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes(
                    "Settings.Delivery Methods_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="96">
                        <Link aria-current="page" to="/deliverygroup">
                          Delivery Methods
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}

                  {auth.permissions.includes(
                    "Settings.Account Groups_is_read"
                  ) ? (
                    <>
                      <Menu.Item key="98">
                        <Link aria-current="page" to="/accountgroup">
                          Account Groups
                        </Link>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {auth.permissions.includes("Settings.Permissions_is_read") ||
                    auth.superuser ? (
                    <Menu.Item key="99">
                      <Link aria-current="page" to="/permissions">
                        Permissions
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </SubMenu>
              ) : (
                ""
              )}
            </Menu>
            {/* </Affix> */}
          </Sider>
        </MediaQuery >
      </>
    );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    businessprofile: state.settings.businessprofile,
    brandlist: state.brand.brandlist,
  };
};

export default connect(mapStateToProps, { activelink, getAllBrand })(Sidebar);
