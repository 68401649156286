import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Spin, Select, Row, Col } from "antd";
import EmployeeList from "./EmployeeList";
import CreateNewEmployee from "./CreateNewEmployee";
import Excelldownload from "./Excelldownload";

const { Option } = Select;

const Maincontent = ({ locationlist, Auth, brand }) => {
  const [updatelist, setUpdatelist] = useState(true);
  const [data, setData] = useState([]);
  // const loading = useRef(true);
  const [loading, setloading] = useState(true);
  const location = useRef("");

  return (
    <>
      <div className="site-layout-background main-frame">
        <Row wrap={false}>
          {brand.name == "ANZARA" && Auth?.profile?.user_role?.id == 34 ?
            null : <><Col flex="auto">
              <Excelldownload data={data} data1={data} />
            </Col>
            </>}

          <Col flex="auto">
            <Select
              showSearch
              placeholder="Location"
              style={{ width: "100%" }}
              onChange={(value) => {
                location.current = value;
                setUpdatelist(!updatelist);
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="all" select>
                All
              </Option>
              {locationlist.map((loc) => {
                return <Option value={loc.id}>{loc.name}</Option>;
              })}
            </Select>
          </Col>
          <Col flex="auto">
            <CreateNewEmployee
              setUpdatelist={setUpdatelist}
              updatelist={updatelist}
            />
          </Col>
        </Row>
        <Spin spinning={loading}>
          <EmployeeList
            loading={loading}
            setloading={setloading}
            updatelist={updatelist}
            setUpdatelist={setUpdatelist}
            setData={setData}
            location={location}
          />
        </Spin>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    locationlist: state.warehouse.locationlist,
    Auth: state.auth,
    brand: state.brand?.brandlist[0],
  };
};

export default connect(mapStateToProps, null)(Maincontent);
